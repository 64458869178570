@import 'import';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-size: var(--md-font);
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: var(--md-font);
  height: 100%;
}

h1 {
  font-size: var(--lg-font);
}

@media only screen and (max-width: 320px) {
  html {
    font-size: var(--md-font);
  }
}

.list-group-item-action:focus {
  background-color: #4a90e2 !important;

  span {
    color: white;
  }
}

.material-icons-outlined {
  vertical-align: middle;
}

.ant-select-selector {
  height: 70% !important;

  input {
    height: 100% !important;
  }
}

#menuItemHide {
  display: none;
}

a {
  color: currentColor;
  text-decoration: none !important;
}

@media only screen and (max-width: 767px) {
  .container {
    padding-right: var(--bs-gutter-x, 0.5rem) !important;
    padding-left: var(--bs-gutter-x, 0.5rem) !important;
  }
}

* {
  margin: 0;
  padding: 0;
}

.header__tab {
  font-size: var(--lg-font);
}

.react-multi-carousel-item {
  display: flex;
  justify-content: center;
}

.pc_details_slider-mark-label {
  font-weight: 700;
  font-size: 12px;
  color: #1a1a2e;
}

.pc_details_slider-label {
  font-weight: 400;
  font-size: 16px;
}

.pc_details_chckbox_label {
  font-weight: 400;
  font-size: 12px;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 40px !important;
}

.MuiSlider-mark {
  height: 6px;
  width: 1px;
  background-color: #bfbfbf;
}

.react-multiple-carousel__arrow:hover {
  background: #80808050 !important;
}

.react-multi-carousel-dot-list {
  bottom: 10px !important;
}
